.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  cursor: pointer;
  background: #d6d6d6;
  border-radius: 3px;
  margin: 5px;
  padding: 4px 7px;
  font-size: 14px;
  display: inline-block;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #fff;
  background: #869791;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  zoom: 1;
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  -webkit-backface-visibility: visible;
  background: #d6d6d6;
  border-radius: 30px;
  margin: 5px 7px;
  transition: opacity .2s;
  display: block;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/*# sourceMappingURL=index.8a7f3911.css.map */
